import React, { useState } from "react";
import "./FeedbackForm.scss";

const FeedbackForm = () => {
  const [password, setPassword] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const correctPassword = "parvatiji123";

  const images = [
    "/Docs/fp-mockup-1.png",
    "/Docs/fp-mockup-2.png",
    "/Docs/fp-mockup-3.png",
    "/Docs/fp-mockup-4.png",
    "/Docs/fp-mockup-5.png",
    "/Docs/fp-feature-graphic.png",
    "/Docs/fp-feature-graphic-2.png",
    "/Docs/fp-logo.png",
    "/Docs/cm-logo.png",
    "/Docs/cm-1.png",
    "/Docs/cm-2.png",
    "/Docs/cm-3.png",
    "/Docs/cm-4.png",
    "/Docs/cm-5.png",
    "/Docs/cm-feature-graphic.png",
    "/Docs/cm-landscape-mockup.png",
  ];

  // APK File
  const aabFile = ["/Docs/fp.aab", "/Docs/cm.aab"];

  const handleDownload = (filePath, fileName) => {
    const link = document.createElement("a");
    link.href = filePath;
    link.download = fileName;
    link.click();
  };

  const handlePasswordSubmit = () => {
    if (password === correctPassword) {
      setIsAuthenticated(true);
    } else {
      alert("Incorrect password!");
    }
  };

  return (
    <div className="feedback-container">
      {!isAuthenticated ? (
        <div className="download-section">
          <h2>Enter Password to Access Content</h2>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter password"
          />
          <button onClick={handlePasswordSubmit}>Submit</button>
        </div>
      ) : (
        <div className="download-section">
          <h2>Download Images</h2>
          <div>
            {images.map((image, index) => (
              <div key={index}>
                <img src={image} alt={`Image ${index + 1}`} width="100" />
                <br />
                <button
                  onClick={() => handleDownload(image, `image${index + 1}.jpg`)}
                >
                  Download Image {index + 1}
                </button>
              </div>
            ))}
          </div>

          <h2>Download AAB Bundles</h2>
          <div>
            <button onClick={() => handleDownload(aabFile[0], "fp.aab")}>
              Download Fact Pins AAB
            </button>
            <button onClick={() => handleDownload(aabFile[1], "cm.aab")}>
              Download Crush Meter AAB
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default FeedbackForm;
